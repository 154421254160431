import { Link } from "react-router-dom";
import heroImg from "../../../../assets/img/slider/slider_img_bg_multiverse.png";
// import heroImg from "../../../../assets/img/slider/slider_img_bg.png";
const heroInformation = {
  id: "1",
  img: heroImg,
  titleTag: "MULTIVERSE ESPORTS",
  title: "This Pages is Currently",
  title2: "Under Maintenance",
  title3: "Please Get in Touch on Instagram",
  btnText: "Instagram",
};
const { img, titleTag, title, title2, title3, btnText } = heroInformation;

const HeroOne = () => {
  return (
    <section id="home" className="slider-area slider-four fix p-relative">
      <div className="slider-active">
        <div
          className="single-slider slider-bg d-flex align-items-center"
          style={{ background: `url(${img}) no-repeat center center / cover` }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6">
                <div className="slider-content s-slider-content pt-50">
                  <h5 data-animation="fadeInDown" data-delay=".4s">
                    {titleTag}
                  </h5>
                  <h3 data-animation="fadeInUp" data-delay=".4s">
                    {title}
                  </h3>
                  <h3 data-animation="fadeInUp" data-delay=".4s">
                    {title2}
                  </h3>
                  <h3 data-animation="fadeInUp" data-delay=".4s">
                    {title3}
                  </h3>
                  <div className="slider-btn">
                    <a
                      href="https://www.instagram.com/multiverse.co.id?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                      className="btn ss-btn mr-15"
                      target="_blank" // Optional: opens the link in a new tab
                      rel="noopener noreferrer" // Recommended for security reasons when using target="_blank"
                    >
                      {btnText}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-5"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroOne;
